import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import logo from '../logo.png';

const SplashScreen = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#000000',
      }}
    >
      <img src={logo} alt="Logo" style={{ width: '150px', marginBottom: '20px' }} />
      <Typography variant="h4" sx={{ color: '#ffffff', marginBottom: '20px' }}>
        CoinGatePad
      </Typography>
      <CircularProgress color="primary" />
    </Box>
  );
};

export default SplashScreen;