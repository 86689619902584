import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import CoinApp from './components/CoinApp';
import SplashScreen from './components/SplashScreen';
import ErrorPage from './components/ErrorPage'; 
import { CssBaseline, ThemeProvider, createTheme, Box, Typography, Button } from '@mui/material';
import axios from 'axios';
import env from "react-dotenv";
import './App.css';
import './css/customStyle.css';

const theme = createTheme();
const telApp = window.Telegram && window.Telegram.WebApp;
const isPhone = window.innerWidth < 600;

function App() {
  const [userData, setUserData] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);
  const [pointCount, setPointCount] = useState(0);
  const [isTelegramMiniApp, setIsTelegramMiniApp] = useState(false);
  const [miningInfo, setMiningInfo] = useState({
    status: 'idle',
    perClick: 2,
    limit: 2000,
    max: 2000,
  });
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [appStatsError, setAppStatsError] = useState(null);
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkEnvironment = async () => {
      if (telApp) {
        console.log("Telegram WebApp detected");
        setIsTelegramMiniApp(true);
        setShowSplashScreen(true);
        telApp.ready();
        await init();
      } else {
        console.log("Not in Telegram WebApp");
        await fetchAppStats();
      }
      setIsLoading(false);
    };

    checkEnvironment();
  }, []);

  useEffect(() => {
    if (isTelegramMiniApp && showSplashScreen) {
      const timer = setTimeout(() => {
        setShowSplashScreen(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isTelegramMiniApp, showSplashScreen]);

  useEffect(() => {
    const fetchData = async () => {
      if (isTelegramMiniApp && userData && userData.id) {
        try {
          await getUserProfile();
          await handleSignUp();
          await handleMiningInfo();
        } catch (error) {
          console.error('Error fetching user data:', error);
          setConnectionError(true);
        }
      }
    };
    
    fetchData();
  }, [userData, isTelegramMiniApp]);

  const init = async () => {
    const search = telApp.initData;
    let data = null;
    if (search && search !== '') {
      const converted = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) {
        return key === "" ? value : decodeURIComponent(value);
      });
      data = JSON.parse(converted.user);
    } else {
      data = {
        "id": 1887509957,
        "first_name": "CoinGatePad",
        "last_name": "",
        "username": "coingatepad",
        "language_code": "en",
        "is_premium": true,
        "allows_write_to_pm": true
      };
    }
    if (data) {
      setUserData(data);
    }
  };

  const getUserProfile = async () => {
    const getFileId = await axios.get(`https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/getUserProfilePhotos?user_id=${userData.id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    const fileId = getFileId.data.result.photos[0][2].file_id
    const getFilePath = await axios.get(`https://api.telegram.org/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/getFile?file_id=${fileId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    const filePath = getFilePath.data.result.file_path;

   setProfileUrl(`https://api.telegram.org/file/bot${process.env.REACT_APP_TELEGRAM_BOT_TOKEN}/${filePath}`)
  }
  
  const handleMiningInfo = () => {
    if (typeof userData.id === 'undefined') return null;
    axios.get(`https://app.coingatepad.com/api/user/${userData.id}`)
      .then((response) => {
        console.log(response)
        if (response.data && 'points' in response.data) {
            setPointCount(response.data.points) 
          }
        if (response.data && 'limit' in response.data) {
          console.log(response.data.limit)
          setMiningInfo(prevMiningInfo => {
              return {...prevMiningInfo, limit: response.data.limit};
          });
        }
      })
      .catch((error) => console.error('Mining error:', error));
  }

  const handleSignUp = () => {
    if (typeof userData.id === 'undefined') return null;
    axios.post('https://app.coingatepad.com/api/signup', 
      {
       userId: userData.id, 
       username: userData.username,
       firstname: userData.first_name,
       lastname: userData.last_name? userData.last_name : 'null',
      }).then((response) => {
        console.log('Signup was success:', response.data);
      })
      .catch((error) => console.error('Signup error:', error));
  };

  const fetchAppStats = async () => {
    try {
      const response = await axios.get('https://app.coingatepad.com/api/app-stats');
      console.log('App stats response:', response.data);
      if (response.data && typeof response.data.totalUsers === 'number' && typeof response.data.totalPoints === 'number') {
        setTotalUsers(response.data.totalUsers);
        setTotalPoints(response.data.totalPoints);
      } else {
        console.error('Invalid app stats data:', response.data);
        setAppStatsError('Failed to fetch app stats. Please try again.');
      }
    } catch (error) {
      console.error('Error fetching app stats:', error);
      setAppStatsError('Failed to fetch app stats. Please try again.');
    }
  };

  const handleReload = () => {
    setConnectionError(false);
    window.location.reload();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isTelegramMiniApp) {
    if (showSplashScreen) {
      return <SplashScreen />;
    }

    if (connectionError) {
      return <ErrorPage onReload={handleReload} />;
    }

    if (isPhone) {
      return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <CoinApp 
            userData={userData} 
            profileUrl={profileUrl} 
            telApp={telApp} 
            userId={userData.id} 
            pointCount={pointCount} 
            setPointCount={setPointCount} 
            miningInfo={miningInfo} 
            setMiningInfo={setMiningInfo}
          />
        </ThemeProvider>
      );
    }
  }

  // Non-Telegram app view (browser)
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'linear-gradient(45deg, #000000 30%, #009900 90%)',
          color: 'white',
          textAlign: 'center',
          padding: 3,
        }}
      >
        <Typography variant="h2" component="h1" gutterBottom>
          Welcome to CoinGatePad
        </Typography>
        {appStatsError ? (
          <Typography color="error">{appStatsError}</Typography>
        ) : (
          <>
            <Typography variant="h4" gutterBottom>
              Total Players: {totalUsers.toLocaleString()}
            </Typography>
            <Typography variant="h4" gutterBottom>
              Total Points: {totalPoints.toLocaleString()}
            </Typography>
          </>
        )}
        <Typography variant="body1" paragraph>
          Join our exciting mining game and earn points! Open this app in Telegram to start playing.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          href="https://t.me/coingatepadbot"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ mt: 2 }}
        >
          Open in Telegram
        </Button>
      </Box>
    </ThemeProvider>
  );
}

export default App;