import React from 'react';
import { Modal, Backdrop, Fade, Button, Typography, Grid, Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import { message } from 'antd';

const slideIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100px);
    opacity: 0;
  }
`;

const AnimatedDiv = styled('div')(({ theme, open }) => ({
  animation: `${open ? slideIn : slideOut} 0.5s`,
  backgroundColor: '#000000',
  backdropFilter: 'blur(4px)',
  padding: '20px',
  borderRadius: '10px',
  maxWidth: '330px',
  height: '85vh',
  margin: 'auto',
  marginTop: '4vh',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

const ScrollableContent = styled(Box)({
  overflowY: 'auto',
  flex: 1,
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});

const FriendsModal = ({ open, handleClose, userData, referralCount, referralList }) => {
   
  const handleReferral = (event) => {
    navigator.clipboard.writeText(event.target.id);
    message.success('Invite link copied to clipboard! Send it to frens and receive coin rewards');
  }

  const handleInvite = () => {
    const url = `https://t.me/coingatepadbot?start=${userData.id}`;
    const text = 'Join me on CoinGatePad Tap2Earn and Play2Earn Gamebot and Start Earning instantly! Airdop Confirmed! Join via my invite link and earn bonus coins';
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
    window.open(telegramShareUrl, '_blank');
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
<Fade in={open}>
        <AnimatedDiv open={open}>
          <Typography variant="h4" color="white" fontWeight={700} sx={{ mb: 2 }}>
            INVITE TO GET BONUS
          </Typography>
          
          <Typography variant="h6" color="white" sx={{ mb: 3 }}>
            Invite other users and earn 50,000 coins from your frens
          </Typography>

          <Box sx={{ 
            backgroundColor: '#3a3329',
            borderRadius: '10px',
            padding: '15px',
            mb: 3
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <img src="https://img.icons8.com/?size=100&id=HNr4OwszbqXk&format=png&color=000000" alt="Invite icon" style={{ marginRight: '10px', width: '30px', height: '30px' }} />
              <Typography variant="h6" color="white" fontWeight={700}>
                INVITE LINK
              </Typography>
            </Box>
            <Typography variant="body1" color="white" sx={{ mb: 2 }}>
              Invite your frens and get bonuses!
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  borderRadius: '10px',
                  backgroundColor: '#ffffff7d',
                  color: 'white',
                  flex: 1,
                  mr: 1,
                  '&:hover': { backgroundColor: '#ffffff7d' }
                }}
                onClick={handleInvite}
              >
                INVITE FRENS
              </Button>
              <Button 
                variant="contained" 
                size="large" 
                sx={{ 
                  borderRadius: '10px', 
                  backgroundColor: '#4a4a4a', 
                  color: 'white',
                  '&:hover': { backgroundColor: '#3a3a3a' }
                }} 
                id={`https://t.me/coingatepadbot?start=${userData.id}`} 
                onClick={handleReferral}
              >
                <ContentCopyIcon />
              </Button>
            </Box>
          </Box>

          <Typography fontFamily={'avenir, sans-serif, serif'} color={'white'} fontWeight={700} fontSize={'1.5rem'} sx={{ mb: 2 }}>
            <img style={{verticalAlign:'middle'}} width="30" height="30" src="https://img.icons8.com/?size=100&id=9542&format=png&color=ffffff" alt="Friends icon"/>
            Your friends ( {referralCount} ):
          </Typography>

          <ScrollableContent>
            {referralList?.map((referral, index) => (
              <Box 
                key={index} 
                style={{ 
                  background: 'linear-gradient(180deg, #1A1E23 0%, #121518 100%)',
                  borderRadius: '10px',
                  color: '#fff',
                  fontWeight: 'bold',
                  padding: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '10px', 
                }}
              >
                <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} fontSize={'1rem'}>
                  {referral.firstname} {referral.lastname}
                </Typography>
              </Box>
            ))}
            {referralList.length === 0 && (
              <Box 
                style={{
                  background: 'linear-gradient(180deg, #1A1E23 0%, #121518 100%)',
                  borderRadius: '10px',
                  color: '#fff',
                  fontWeight: 'bold',
                  padding: '15px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '10px', 
                }}
              >
                <Typography fontFamily={'avenir, sans-serif, serif'} fontWeight={700} fontSize={'1.5rem'}>
                  No friends
                </Typography>
              </Box>
            )}
          </ScrollableContent>

          <CloseIcon 
            onClick={handleClose} 
            style={{ 
              position: 'absolute', 
              top: '10px', 
              right: '10px', 
              cursor: 'pointer',
              color: 'white'
            }} 
          />
        </AnimatedDiv>
      </Fade>
    </Modal>
  );
};

export default FriendsModal;