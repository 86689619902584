import React, { useMemo } from 'react';
import { Modal, Backdrop, Fade, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

const AnimatedDiv = styled('div')(({ theme }) => ({
  backgroundColor: '#000000',
  backdropFilter: 'blur(4px)',
  padding: '20px',
  borderRadius: '10px',
  maxWidth: '330px',
  height: '85vh',
  margin: 'auto',
  marginTop: '4vh',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.5s, opacity 0.5s',
  transform: 'translateY(0)',
  opacity: 1,
  '&.entering': {
    transform: 'translateY(100px)',
    opacity: 0,
  },
  '&.exiting': {
    transform: 'translateY(100px)',
    opacity: 0,
  },
}));

const ScrollableContent = styled(Box)({
  overflowY: 'auto',
  flex: 1,
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#1E293B',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#4A5A6F',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#637898',
  },
});

const LeaderboardItem = styled(Box)({
  textAlign: 'left',
  background: 'linear-gradient(180deg, #1A1E23 0%, #121518 100%)',
  borderRadius: '10px',
  color: '#fff',
  fontWeight: 'bold',
  padding: '15px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
});

const PointsBadge = styled('span')({
  backgroundColor: '#1E293B',
  color: '#1BD19F',
  borderRadius: '20px',
  padding: '8px 16px',
  fontSize: '14px',
  fontWeight: 'bold',
  marginLeft: '10px',
});

const CloseButton = styled(CloseIcon)({
  position: 'absolute',
  top: '10px',
  right: '10px',
  cursor: 'pointer',
  color: 'white',
});

const LeaderboardModal = ({ open, handleClose, leaderboardList }) => {
  const sortedLeaderboard = useMemo(() => 
    [...leaderboardList].sort((a, b) => b.points - a.points),
    [leaderboardList]
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <AnimatedDiv>
          <Typography variant="h5" sx={{ color: '#fff', fontWeight: 'bold', mb: 2 }}>
            Leaderboard
          </Typography>
          <Typography variant="body2" sx={{ color: '#4A5A6F', mb: 2 }}>
            Here are the top Players
          </Typography>
          <ScrollableContent>
            {sortedLeaderboard.length > 0 ? (
              sortedLeaderboard.map((player, index) => (
                <LeaderboardItem key={player.id || index}>
                  <Typography fontFamily="avenir, sans-serif" fontWeight={700} fontSize="1rem">
                    {`${index + 1}. ${player.username}`}
                    <PointsBadge>{player.points}</PointsBadge>
                  </Typography>
                </LeaderboardItem>
              ))
            ) : (
              <LeaderboardItem>
                <Typography fontFamily="avenir, sans-serif" color="white" fontWeight={700} fontSize="1rem">
                  Ranking is empty
                </Typography>
              </LeaderboardItem>
            )}
          </ScrollableContent>
          <CloseButton onClick={handleClose} />
        </AnimatedDiv>
      </Fade>
    </Modal>
  );
};

export default LeaderboardModal;