import React, { useCallback, ReactElement, useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Button, Typography, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';
import { keyframes } from '@emotion/react';
import { useAdsgram } from "./useAdsgram.js";
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

const slideIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100px);
    opacity: 0;
  }
`;

const AnimatedDiv = styled('div')(({ theme, open }) => ({
  animation: `${open ? slideIn : slideOut} 0.5s`,
  backgroundColor: '#000000',
  backdropFilter: 'blur(4px)',
  padding: '20px',
  borderRadius: '10px',
  maxWidth: '330px',
  height: '85vh',
  margin: 'auto',
  marginTop: '4vh',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

const ScrollableContent = styled(Box)({
  overflowY: 'auto',
  flex: 1,
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});

const AdButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1E1E1E',
  color: '#FFFFFF',
  borderRadius: '10px',
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#2A2A2A',
  },
}));

const AdIcon = styled('img')({
  width: '30px',
  height: '30px',
  marginRight: '10px',
});

const TaskButton = styled(Button)(({ theme, completed, visited, timerRunning }) => ({
  backgroundColor: completed ? '#1E293B' : timerRunning ? '#3A4A5F' : visited ? '#2A3A4F' : '#1A1E23',
  color: completed ? '#1BD19F' : timerRunning ? '#FFA500' : '#01F0FF',
  borderRadius: '20px',
  padding: '8px 16px',
  fontSize: '14px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: completed ? '#2A3A4F' : timerRunning ? '#4A5A6F' : visited ? '#3A4A5F' : '#252B33',
  },
}));

const TaskCard = styled('div')(({ theme }) => ({
  background: 'linear-gradient(180deg, #1A1E23 0%, #121518 100%)',
  borderRadius: '10px',
  padding: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
}));

const TabBar = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '10px',
});

const TabButton = styled(Button)(({ active }) => ({
  backgroundColor: active ? '#01F0FF' : '#2A3A4F',
  color: active ? '#000' : '#FFF',
  padding: '10px 20px',
  borderRadius: '20px',
  marginRight: '10px',
  '&:hover': {
    backgroundColor: '#01F0FF',
    color: '#000',
  },
}));

const allTasks = [
  { id: 1, category: "Social", title: "Subscribe to Our Telegram Channel", points: 5000, completed: false, visited: false, link: "https://t.me/coingatepad", },
  { id: 2, category: "Social", title: "Join Our Telegram Group", points: 5000, completed: false, visited: false, link: "https://t.me/coingatepadchat", },
  { id: 3, category: "Social", title: "Follow us On X", points: 5000, completed: false, visited: false, link: "https://x.com/coingatepad", },
  { id: 4, category: "Social", title: "Subscribe to Our YouTube Channel", points: 5000, completed: false, visited: false, link: "https://youtube.com/@coingatepad", },
  { id: 5, category: "Social", title: "Follow Us On Facebook", points: 5000, completed: false, visited: false, link: "https://facebook.com/coingatepad", },
  { id: 6, category: "Social", title: "Join Our Discord Server", points: 5000, completed: false, visited: false, link: "https://discord.gg/pdzrW9sPr2", },
  { id: 7, category: "Invite", title: "Invite 5 friends to CGP", points: 20000, completed: false, visited: false, requiredInvites: 5 },
  { id: 8, category: "Invite", title: "Invite 10 friends to CGP", points: 45000, completed: false, visited: false, requiredInvites: 10 },
  { id: 9, category: "Invite", title: "Invite 20 friends to CGP", points: 100000, completed: false, visited: false, requiredInvites: 20 },
  { id: 10, category: "Invite", title: "Invite 50 friends to CGP", points: 220000, completed: false, visited: false, requiredInvites: 50 },
  { id: 11, category: "Invite", title: "Invite 100 friends to CGP", points: 500000, completed: false, visited: false, requiredInvites: 100 },

];

const TasksModal = ({ open, handleClose, userData, setPointCount }) => {
  const [tasks, setTasks] = useState(allTasks);
  const [timers, setTimers] = useState({});
  const [activeTab, setActiveTab] = useState('active');

  useEffect(() => {
    const fetchUserTasks = async () => {
      try {
        const response = await axios.get(`https://app.coingatepad.com/api/user/${userData.id}/tasks`);
        const userTasks = response.data.completedTasks;

        const updatedTasks = allTasks.map(task => ({
          ...task,
          completed: userTasks.includes(task.id),
          visited: false,
        }));
        setTasks(updatedTasks);
      } catch (error) {
        console.error("Error fetching user tasks:", error);
      }
    };

    if (open) {
      fetchUserTasks();
    }
  }, [userData.id, open]);

  useEffect(() => {
    const intervalIds = Object.keys(timers).map(taskId => {
      return setInterval(() => {
        setTimers(prevTimers => ({
          ...prevTimers,
          [taskId]: prevTimers[taskId] - 1
        }));
      }, 1000);
    });

    return () => intervalIds.forEach(clearInterval);
  }, [timers]);

  const [inviteCount, setInviteCount] = useState(0);

  useEffect(() => {
    const fetchUserInvites = async () => {
      try {
        const response = await axios.get(`https://app.coingatepad.com/api/user/${userData.id}/invites`);
        setInviteCount(response.data.inviteCount);
      } catch (error) {
        console.error("Error fetching user invites:", error);
      }
    };

    if (open) {
      fetchUserInvites();
    }
  }, [userData.id, open]);

  const handleInviteTaskCompletion = async (taskId, points, requiredInvites) => {
    if (inviteCount >= requiredInvites) {
      try {
        const response = await axios.post(`https://app.coingatepad.com/api/user/${userData.id}/complete-invite-task`, {
          taskId,
          points
        });
    
        if (response.status === 200) {
          setTasks(tasks.map(task => 
            task.id === taskId ? { ...task, completed: true } : task
          ));
          setPointCount(response.data.newPoints);
        }
      } catch (error) {
        console.error("Error completing the invite task:", error);
      }
    }
  };


  const onReward = useCallback(() => {
    alert('Reward');
  }, []);

  const onError = useCallback((result) => {
    alert(JSON.stringify(result, null, 4));
  }, []);

  const showAd = useAdsgram({ blockId: "2734", onReward, onError });

  const handleWatchAd = async () => {
    try {
      const response = await showAd(); // Assuming showAd returns a promise
      if (response.success) {
        // Ad was successfully watched, now reward the user
        const rewardResponse = await fetch(`https://app.coingatepad.com/api/user/${userData.id}/advert`, {
          method: 'POST',
        });
        if (rewardResponse.ok) {
          const result = await rewardResponse.json();
          setPointCount(result.newPoints);
          // Optionally, update the UI to reflect the new points
        }
      }
    } catch (error) {
      console.error("Error watching ad or claiming reward:", error);
    }
  };



  const handleTaskStart = (taskId, link) => {
    window.open(link, "_blank");
    setTasks(tasks.map(task => 
      task.id === taskId ? { ...task, visited: true } : task
    ));
    setTimers(prevTimers => ({
      ...prevTimers,
      [taskId]: 60 // Start a 60-second timer
    }));
  };

  const handleTaskCompletion = async (taskId, points) => {
    try {
      const response = await axios.post(`https://app.coingatepad.com/api/user/${userData.id}/complete-task`, {
        taskId,
        points
      });
  
      if (response.status === 200) {
        setTasks(tasks.map(task => 
          task.id === taskId ? { ...task, completed: true, visited: false } : task
        ));
        setPointCount(response.data.newPoints);
        // Remove the timer for this task
        setTimers(prevTimers => {
          const { [taskId]: _, ...rest } = prevTimers;
          return rest;
        });
      }
    } catch (error) {
      console.error("Error completing the task:", error);
    }
  };

  const filteredTasks = tasks.filter(task => 
    activeTab === 'active' ? !task.completed : task.completed
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <AnimatedDiv open={open}>
          <Typography variant="h5" style={{ color: '#fff', fontWeight: 'bold', marginBottom: '20px' }}>
            Complete these tasks to earn extra points!
          </Typography>
          <Typography variant="body2" style={{ color: '#4A5A6F' }}>
            ⚠️ These are promo campaigns. We cannot control or be held responsible for the content in them
          </Typography>
          
          <TabBar>
            <TabButton 
              active={activeTab === 'active'} 
              onClick={() => setActiveTab('active')}
            >
              Active
            </TabButton>
            <TabButton 
              active={activeTab === 'completed'} 
              onClick={() => setActiveTab('completed')}
            >
              Completed
            </TabButton>
          </TabBar>
         {/*  <AdButton onClick={handleWatchAd}>
            <AdIcon src="https://www.coingatepad.com/Logo.png" alt="Ad Icon" />
            <Box>
              <Typography variant="button">Watch an Ad</Typography>
              <Typography variant="caption" display="block">Earn: 1,000</Typography>
            </Box>
          </AdButton>*/}
          <ScrollableContent>
            <Grid container spacing={2}>
              {filteredTasks.map((task) => (
                <Grid key={task.id} item xs={12}>
                  <TaskCard>
                    <div>
                      <Typography variant="subtitle1" style={{ color: '#fff', fontWeight: 'bold' }}>{task.title}</Typography>
                      <Typography variant="body2" style={{ color: '#01F0FF' }}>{task.points} Points</Typography>
                    </div>
                    {task.category === "Invite" ? (
                      <TaskButton
                        variant="contained"
                        completed={task.completed}
                        onClick={() => handleInviteTaskCompletion(task.id, task.points, task.requiredInvites)}
                        disabled={inviteCount < task.requiredInvites || task.completed}
                      >
                        {task.completed ? "Claimed" : "Claim"}
                      </TaskButton>
                    ) : (
                    <TaskButton
                      variant="contained"
                      completed={task.completed}
                      visited={task.visited}
                      timerRunning={timers[task.id] > 0}
                      onClick={() => {
                        if (!task.completed) {
                          if (!task.visited) {
                            handleTaskStart(task.id, task.link);
                          } else if (timers[task.id] <= 0) {
                            handleTaskCompletion(task.id, task.points);
                          }
                        }
                      }}
                    >
                      {task.completed ? "Claimed" : 
                       timers[task.id] > 0 ? `Wait Verifying ${timers[task.id]}s` :
                       task.visited ? "Claim" : "Start"}
                    </TaskButton>
                     )}
                  </TaskCard>
                </Grid>
              ))}
            </Grid>
          </ScrollableContent>
          <CloseIcon 
            onClick={handleClose} 
            style={{ 
              position: 'absolute', 
              top: '10px', 
              right: '10px', 
              cursor: 'pointer',
              color: 'white'
            }} 
          />
        </AnimatedDiv>
      </Fade>
    </Modal>
  );
};

export default TasksModal;
