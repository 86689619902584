import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Typography, TextField, Button } from '@mui/material';
import axios from 'axios';
import { styled } from '@mui/system';
import { keyframes } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';

const slideIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100px);
    opacity: 0;
  }
`;

const AnimatedDiv = styled('div')(({ theme, open }) => ({
  animation: `${open ? slideIn : slideOut} 0.5s`,
  backgroundColor: '#000000',
  backdropFilter: 'blur(4px)',
  padding: '20px',
  borderRadius: '10px',
  maxWidth: '330px',
  height: '85vh',
  margin: 'auto',
  marginTop: '4vh',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

const MinecraftImage = styled('img')({
  width: '100%',
  maxWidth: '200px',
  margin: '20px auto',
});

const ClockAnimation = styled('div')({
  width: '100px',
  height: '100px',
  borderRadius: '50%',
  border: '4px solid #fff',
  position: 'relative',
  margin: '20px auto',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '4px',
    height: '40px',
    background: '#fff',
    transformOrigin: 'top center',
    animation: 'rotate 3s linear infinite',
  },
  '@keyframes rotate': {
    '0%': { transform: 'translate(-50%, -100%) rotate(0deg)' },
    '100%': { transform: 'translate(-50%, -100%) rotate(360deg)' },
  },
});

const WordScrambleGame = ({ open, handleClose, userData, setPointCount, onGameEnd }) => {
  const [scrambledWord, setScrambledWord] = useState('');
  const [hint, setHint] = useState('');
  const [userGuess, setUserGuess] = useState('');
  const [timeLeft, setTimeLeft] = useState(30);
  const [gameState, setGameState] = useState('loading');
  const [correctWord, setCorrectWord] = useState('');
  const [waitTimeLeft, setWaitTimeLeft] = useState(0);

  useEffect(() => {
    if (open) {
      checkGameAvailability();
    }
  }, [open]);

  useEffect(() => {
    let timer;
    if (gameState === 'playing' && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0 && gameState === 'playing') {
      endGame('timeout');
    }
    return () => clearInterval(timer);
  }, [gameState, timeLeft]);

  const checkGameAvailability = async () => {
    try {
      const response = await axios.get(`https://app.coingatepad.com/api/word-game/${userData.id}/availability`);
      if (response.data.canPlay) {
        startNewGame();
      } else {
        setWaitTimeLeft(response.data.waitTime);
        setGameState('waiting');
      }
    } catch (error) {
      console.error('Error checking game availability:', error);
      setGameState('error');
    }
  };

  const startNewGame = async () => {
    try {
      const response = await axios.get(`https://app.coingatepad.com/api/word-game/${userData.id}`);
      const { word, hint } = response.data;
      setCorrectWord(word);
      setHint(hint);
      setScrambledWord(scrambleWord(word));
      setTimeLeft(30);
      setGameState('playing');
    } catch (error) {
      console.error('Error starting new game:', error);
      setGameState('error');
    }
  };

  const scrambleWord = (word) => {
    return word.split('').sort(() => Math.random() - 0.5).join('');
  };

  const handleGuessChange = (event) => {
    setUserGuess(event.target.value);
  };

  const submitGuess = async () => {
    if (userGuess.toLowerCase() === correctWord.toLowerCase()) {
      try {
        const response = await axios.post(`https://app.coingatepad.com/api/word-game/${userData.id}/end`, { result: 'won' });
        setPointCount(prevPoints => prevPoints + 10000); // Update points locally
        endGame('won');
      } catch (error) {
        console.error('Error updating points:', error);
        endGame('lost');
      }
    } else {
      setUserGuess('');
    }
  };

  const endGame = async (result) => {
    setGameState(result);
    try {
      await axios.post(`https://app.coingatepad.com/api/word-game/${userData.id}/end`, { result });
    } catch (error) {
      console.error('Error ending game:', error);
    }
    onGameEnd(result);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <AnimatedDiv open={open}>
          <Typography variant="h5" style={{ color: '#fff', fontWeight: 'bold', marginBottom: '20px' }}>Word Cipher</Typography>

          {gameState === 'loading' && <Typography>Loading game...</Typography>}

          {gameState === 'error' && <Typography>Error loading game. Please try again later.</Typography>}

          {gameState === 'waiting' && (
            <>
              <MinecraftImage src="https://coingatepad.com/timeout.png" alt="Timeout" />
              <Typography style={{ color: '#01F0FF' }}>
                You can play again in: {formatTime(waitTimeLeft)}
              </Typography>
            </>
          )}

          {gameState === 'playing' && (
            <>
              <Typography style={{ color: '#fff', fontWeight: 'bold' }}>Time left: {timeLeft} seconds</Typography>
              <Typography style={{ color: '#01F0FF' }}>Hint: {hint}</Typography>
              <Typography variant="h5" style={{ color: '#fff', fontWeight: 'bold' }}>{scrambledWord}</Typography>
              <TextField
                style={{ backgroundColor: '#ffffff', marginTop: '16px' }}
                value={userGuess}
                onChange={handleGuessChange}
                placeholder="Enter your guess"
              />
              <Button
                variant="contained"
                onClick={submitGuess}
                style={{ marginTop: '16px', backgroundColor: '#01F0FF', color: '#000' }}
              >
                Submit Guess
              </Button>
              <Typography style={{ color: '#FFD700', fontWeight: 'bold', marginTop: '16px' }}>Reward: 10,000</Typography>
            </>
          )}

          {gameState === 'won' && (
            <>
              <MinecraftImage src="https://coingatepad.com/victory.png" alt="Victory" />
              <Typography style={{ color: '#4A5A6F', fontWeight: 'bold' }}>Congratulations! You won!
              </Typography>
            </>
          )}
          {gameState === 'lost' && (
            <>
              <MinecraftImage src="https://coingatepad.com/lost.png" alt="Lost" />
              <Typography style={{ color: '#4A5A6F', fontWeight: 'bold' }}>Sorry, that's incorrect. Try again in {formatTime(waitTimeLeft)}.
              </Typography>
            </>
          )}
          {gameState === 'timeout' && (
            <>
              <MinecraftImage src="https://coingatepad.com/timeout.png" alt="Timeout" />
              <Typography style={{ color: '#4A5A6F', fontWeight: 'bold' }}>Time's up! Try again in {formatTime(waitTimeLeft)}.
              </Typography>
              
            </>
          )}

          <CloseIcon
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              cursor: 'pointer',
              color: 'white'
            }}
          />
        </AnimatedDiv>
      </Fade>
    </Modal>
  );
};

export default WordScrambleGame;