import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const ErrorPage = ({ onReload }) => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1e1e1e',
        color: 'white',
        textAlign: 'center',
        padding: 3,
      }}
    >
      <Box
        component="img"
        src="/error-screen.png"
        alt="Connection Failed"
        sx={{ width: '200px', marginBottom: 2 }}
      />
      <Typography variant="h4" gutterBottom>
        CONNECTION FAILED
      </Typography>
      <Typography variant="body1" paragraph>
        The connection seems to be off. Please reload the app and this should fix the problem
      </Typography>
      <Button
        variant="contained"
        onClick={onReload}
        sx={{
          backgroundColor: '#f0a500',
          color: 'white',
          '&:hover': {
            backgroundColor: '#d09400',
          },
          width: '200px',
          height: '50px',
          fontSize: '1.2rem',
          fontWeight: 'bold',
        }}
      >
        RELOAD BOT
      </Button>
    </Box>
  );
};

export default ErrorPage;