import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import axios from 'axios';
import ViewGift from '../images/background.gif';

const GateGame = ({ userData, tickets, onGameEnd, onBack }) => {
  const [score, setScore] = useState(0);
  const scoreRef = useRef(0);
  const [timeLeft, setTimeLeft] = useState(30);
  const [gameState, setGameState] = useState('playing');
  const [tomatoes, setTomatoes] = useState([]);

  const moveTomatoes = useCallback(() => {
    setTomatoes(prevTomatoes => 
      prevTomatoes.map(tomato => ({
        ...tomato,
        y: tomato.y + tomato.speed,
      })).filter(tomato => tomato.y < window.innerHeight)
    );
  }, []);

  useEffect(() => {
    if (tickets > 0) {
      startGame();
    } else {
      alert('No tickets left!');
      onBack();
    }
  }, []);

  const startGame = async () => {
    scoreRef.current = 0;
    setScore(0);
    setTimeLeft(30);
    setTomatoes([]);
    setGameState('playing');

    try {
      await axios.post(`https://app.coingatepad.com/api/user/${userData.id}/use-ticket`);
    } catch (error) {
      console.error('Failed to use ticket:', error);
      onBack();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer);
          endGame();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    const tomatoSpawnTimer = setInterval(() => {
      spawnTomato();
    }, 500);

    const tomatoMoveTimer = setInterval(() => {
      moveTomatoes();
    }, 25);

    return () => {
      clearInterval(timer);
      clearInterval(tomatoSpawnTimer);
      clearInterval(tomatoMoveTimer);
    };
  };

  const endGame = async () => {
    setGameState('ended');
    try {
      await axios.post(`https://app.coingatepad.com/api/user/${userData.id}/add-point`, { points: scoreRef.current });
    } catch (error) {
      console.error('Error updating score after game:', error);
      alert('Failed to submit score. Please try again.');
    }
  };

  const handleTomatoClick = (id, points) => {
    scoreRef.current += points;
    setScore(scoreRef.current);
    setTomatoes(prevTomatoes => prevTomatoes.filter(tomato => tomato.id !== id));
  };

  const spawnTomato = () => {
    const size = Math.random() < 0.5 ? 'small' : (Math.random() < 0.8 ? 'medium' : 'large');
    const newTomato = {
      id: Date.now() + Math.random(),
      x: Math.random() * 90,
      y: -50,
      size,
      speed: size === 'small' ? 3 : (size === 'medium' ? 2 : 1),
      points: size === 'small' ? 2 : (size === 'medium' ? 3 : 5),
    };
    setTomatoes(prevTomatoes => [...prevTomatoes, newTomato]);
  };

  const handleBackToMain = () => {
    onGameEnd(scoreRef.current);
  };

  return (
    <Box sx={{ 
      position: 'relative', 
      width: '100%', 
      height: '100vh', 
      overflow: 'hidden', 
      backgroundImage: `url(${ViewGift})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      {gameState === 'playing' && (
        <>
          <Box sx={{ position: 'absolute', top: 10, left: 10, bgcolor: 'rgba(255,255,255,0.7)', p: 1, borderRadius: 2 }}>
            <Typography variant="h6">Time: {timeLeft}</Typography>
          </Box>
          <Box sx={{ position: 'absolute', top: 10, right: 10, bgcolor: 'rgba(255,255,255,0.7)', p: 1, borderRadius: 2 }}>
            <Typography variant="h6">Score: {score}</Typography>
          </Box>
          {tomatoes.map(tomato => (
            <Box
              key={tomato.id}
              onClick={() => handleTomatoClick(tomato.id, tomato.points)}
              sx={{
                position: 'absolute',
                left: `${tomato.x}%`,
                top: tomato.y,
                width: tomato.size === 'small' ? 50 : (tomato.size === 'medium' ? 60 : 70),
                height: tomato.size === 'small' ? 50 : (tomato.size === 'medium' ? 60 : 70),
                bgcolor: 'green',
                borderRadius: '50%',
                cursor: 'pointer',
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  top: -5,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 10,
                  height: 10,
                  bgcolor: 'darkgreen',
                  borderRadius: '50%',
                }
              }}
            />
          ))}
        </>
      )}
      {gameState === 'ended' && (
        <Box sx={{ 
          textAlign: 'center', 
          mt: 4, 
          bgcolor: 'rgba(255,255,255,0.9)',
          p: 4,
          borderRadius: 4,
          maxWidth: 400,
          margin: 'auto'
        }}>
          <Typography variant="h4" gutterBottom>Game Over!</Typography>
          <Typography variant="h5" gutterBottom>Your Score: {score}</Typography>
          <Box 
            component="img" 
            src="https://img.icons8.com/?size=200&id=YLcBDJsEZ49a&format=png&color=000000" 
            alt="Congrats" 
            sx={{ width: 200, height: 200, margin: 'auto', display: 'block', my: 2 }}
          />
          <Button 
            variant="contained" 
            onClick={handleBackToMain}
            sx={{ 
              mt: 2, 
              display: 'block', 
              width: '100%', 
              bgcolor: 'black', 
              color: 'white',
              '&:hover': { bgcolor: 'darkgrey' }
            }}
          >
            Back to Main
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default GateGame;