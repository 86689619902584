import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, Typography, TextField, Avatar, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { message } from 'antd';
import { CssBaseline, ThemeProvider, createTheme, LinearProgress } from '@mui/material';
import SkinsModal from './Skins';
import FriendsModal from './Friends';
import LeaderboardModal from './Leaderboard';
import TasksModal from './Tasks';
import WordScrambleGame from './WordScrambleGame';
import GateGame from './GateGame';
import { keyframes } from '@emotion/react';
import axios from 'axios';

// Import images
import defaultCoin from '../images/Coin.png';
import OrangeCoin from '../images/Orange.png';
import GuardCoin from '../images/Guard.png';
import BattleCoin from '../images/Battle.png';
import ViewGift from '../images/background.gif';

const theme = createTheme();
const isDesktop = window.innerWidth > 1000;

const GoldButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(0,0,0)',
  borderRadius: 15,
  width: '22%',
  margin: '5px',
  padding: '8px',
  fontFamily: 'avenir',
  fontSize: '16px',
  textTransform: 'Capitalize',
  fontWeight: 800,
  boxShadow: 'none',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(50,50,50)',
    boxShadow: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    padding: '6px',
  },
}));

const PlayNowButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FFD700',
  color: 'black',
  fontWeight: 'bold',
  padding: '10px 20px',
  borderRadius: '0 20px 20px 0',
  '&:hover': {
    backgroundColor: '#FFC700',
  },
}));

const CoinLogo = styled(Box)(({ theme }) => ({
  width: '25vw',
  marginBottom: '15px',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    width: '50vw',
    marginBottom: '25px',
  },
}));

const expand = keyframes`
  from, to { transform: scale(1); }
  50% { transform: scale(1.1); }
`;

const fontSizeAnim = keyframes`
   from, to { font-size: ${isDesktop ? '22px' : '26px'}; }
   50% { font-size: ${isDesktop ? '22px' : '26px'}; }
`;

const floatUpAndFadeOut = keyframes`
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-100px);
    opacity: 0;
  }
`;

const flyAndFadeOut = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translate(calc(100vw + 100px), -100vh) rotate(45deg);
    opacity: 0;
  }
`;

const FlyingPlane = styled('img')({
  position: 'fixed',
  width: '30px',
  height: '30px',
  pointerEvents: 'none',
  animation: `${flyAndFadeOut} 2s linear forwards`,
});

const BackgroundAnimation = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundImage: `url(${ViewGift})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  zIndex: -1,
});

export default function CoinApp({ userData, profileUrl, telApp, userId, setPointCount, miningInfo, setMiningInfo }) {
  const [localPointCount, setLocalPointCount] = useState(0);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [openSkins, setOpenSkins] = useState(false);
  const [openFriends, setOpenFriends] = useState(false);
  const [openLeaderboard, setOpenLeaderboard] = useState(false);
  const [openTasks, setOpenTasks] = useState(false);
  const [openWordGame, setOpenWordGame] = useState(false);
  const [lastVisitDate, setLastVisitDate] = useState(null);
  const [showGateGame, setShowGateGame] = useState(false);
  const [tickets, setTickets] = useState(0);
  const [expandAnimation, setExpandAnimation] = useState('');
  const [fontSizeAnimation, setFontSizeAnimation] = useState('');
  const [planes, setPlanes] = useState([]);
  const [userAddress, setUserAddress] = useState('');
  const [userSkins, setUserSkins] = useState([]);
  const [userCurrentSkinID, setUserCurrentSkinID] = useState(1);
  const [userCurrentSkinImage, setUserCurrentSkinImage] = useState(defaultCoin);
  const [userCurrentReferrals, setUserCurrentReferrals] = useState(0);
  const [userReferralsInfo, setUserReferralsInfo] = useState([]);
  const [leaderboardList, setLeaderboardList] = useState([]);
  const [audio] = useState(new Audio('https://assets.mixkit.co/active_storage/sfx/216/216.wav'));

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://app.coingatepad.com/api/user/${userId}`);
        if (response.data) {
          setLocalPointCount(response.data.points);
          setPointCount(response.data.points);
          setTickets(response.data.tickets);
          setUserSkins(response.data.skins);
          setUserCurrentSkinID(response.data.skinID);
          setUserCurrentReferrals(response.data.referrals);
          setUserReferralsInfo(response.data.referralsInfo || []);
          updateCurrentSkinImage(response.data.skinID);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
    const interval = setInterval(fetchUserData, 5000); // Poll every 5 seconds

    return () => clearInterval(interval);
  }, [userId, setPointCount]);

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const response = await axios.get('https://app.coingatepad.com/api/leaderboard');
        setLeaderboardList(response.data.users);
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      }
    };

    fetchLeaderboard();
  }, []);

  const updateCurrentSkinImage = (skinID) => {
    switch (skinID) {
      case 1:
        setUserCurrentSkinImage(defaultCoin);
        break;
      case 2:
        setUserCurrentSkinImage(OrangeCoin);
        break;
      case 3:
        setUserCurrentSkinImage(GuardCoin);
        break;
      case 4:
        setUserCurrentSkinImage(BattleCoin);
        break;
      default:
        setUserCurrentSkinImage(defaultCoin);
    }
  };

  const handleCoinClick = async (event) => {
    if (miningInfo.limit !== 0) {
      const newPoints = localPointCount + miningInfo.perClick;
      setLocalPointCount(newPoints);
      setMiningInfo(prevInfo => ({ ...prevInfo, limit: prevInfo.limit - miningInfo.perClick, status: 'mining' }));
    
      setExpandAnimation(`${expand} 0.1s ease`);
      setFontSizeAnimation(`${fontSizeAnim} 0.1s ease`);
      audio.play();

      const x = event.clientX;
      const y = event.clientY;

      // Add a new plane
      const newPlane = { id: Date.now(), x, y };
      setPlanes(prevPlanes => [...prevPlanes, newPlane]);

      // Remove the plane after animation completes
      setTimeout(() => {
        setPlanes(prevPlanes => prevPlanes.filter(plane => plane.id !== newPlane.id));
      }, 2000);

       // create a new point element
       setTimeout(() => {
         setExpandAnimation('');
         setFontSizeAnimation('');
       }, 200);

      try {
        await axios.post(`https://app.coingatepad.com/api/user/${userId}/add-point`, {
          points: miningInfo.perClick,
        });
      } catch (error) {
        console.error('Error updating score:', error);
        setLocalPointCount(prevPoints => prevPoints - miningInfo.perClick);
      }
    } else {
      setMiningInfo(prevInfo => ({ ...prevInfo, status: 'stop' }));
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.showAlert('Mining limit reached. Please try again later.');
      }
    }
  };


    // Replenish tickets 
    useEffect(() => {
      const checkAndReplenishTickets = async () => {
        try {
          // Get the user's last visit date from the server
          const response = await axios.get(`https://app.coingatepad.com/api/user/${userId}/last-visit`);
          const lastVisit = response.data.lastVisitDate;
          const today = new Date().toISOString().split('T')[0];
  
          if (lastVisit !== today) {
            // It's a new day, replenish tickets
            await replenishTickets();
            // Update the last visit date on the server
            await axios.post(`https://app.coingatepad.com/api/user/${userId}/update-last-visit`, { date: today });
          }
  
          setLastVisitDate(today);
        } catch (error) {
          console.error('Error checking and replenishing tickets:', error);
        }
      };
  
      checkAndReplenishTickets();
    }, [userId]); // Run this effect when the component mounts or userId changes
  
    const replenishTickets = async () => {
      try {
        const response = await axios.post(`https://app.coingatepad.com/api/user/${userId}/replenish-tickets`, {
          tickets: 5
        });
        
        if (response.data.success) {
          console.log('Tickets replenished successfully');
          // You might want to update the user's ticket count in the UI here
          // setUserTickets(response.data.newTicketCount);
        } else {
          console.error('Failed to replenish tickets');
        }
      } catch (error) {
        console.error('Error replenishing tickets:', error);
      }
    };

  const handleWithdraw = async () => {
    if (localPointCount >= 5000) {
      try {
        await axios.post('https://app.coingatepad.com/api/withdraw', {
          userId,
          userAddress,
          points: localPointCount
        });
        message.success('Withdrawal was successful, please check your wallet!');
        if (window.Telegram && window.Telegram.WebApp) {
          window.Telegram.WebApp.close();
        }
      } catch (error) {
        console.error('Withdrawal error:', error);
        message.error('Something went wrong, please check SendChain bot!');
      }
    } else {
      alert('Insufficient balance. You need to have at least 5000 points to withdraw');
    }
    setOpenWithdraw(false);
  };

  const handlePlayNow = () => {
    if (tickets > 0) {
      setShowGateGame(true);
    } else {
      alert('No tickets left! Please wait for tomorrow or invite friends to get more tickets.');
    }
  };

  const handleGateGameEnd = (score) => {
    setLocalPointCount(prevPoints => prevPoints + score);
    setTickets(prevTickets => prevTickets - 1);
    setShowGateGame(false);
  };

  const handleWordGameEnd = async (result) => {
    setOpenWordGame(false);
    if (result === 'won') {
      const points = 10000;
      message.success(`Congratulations! You earned ${points} points!`);
      try {
        await axios.post(`https://app.coingatepad.com/api/user/${userId}/add-point`, { points });
        setLocalPointCount(prevPoints => prevPoints + points);
      } catch (error) {
        console.error('Error updating points:', error);
      }
    } else {
      message.info('Better luck next time!');
    }
  };

  const getUserRank = (points) => {
    if (points > 1000000000) return '👑 Platinum';
    if (points > 50000000) return '💎 Diamond';
    if (points > 10000000) return '🏆 Gold';
    if (points > 1000000) return '🥈 Silver';
    if (points > 100000) return '🥉 Bronze';
    return 'Nobe';
  };

  if (showGateGame) {
    return (
      <GateGame 
        userData={userData} 
        tickets={tickets}
        onGameEnd={handleGateGameEnd} 
        onBack={() => setShowGateGame(false)}
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BackgroundAnimation />
      <Box sx={{    
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2,
        overflow: 'hidden',
      }}>

        {planes.map(plane => (
            <FlyingPlane
              key={plane.id}
              src="https://img.icons8.com/?size=100&id=TCnKnYZFoOzM&format=png&color=ffffff"
              style={{
                left: `${plane.x}px`,
                top: `${plane.y}px`,
              }}
            />
          ))}
        {/* User Info */}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '10px',
          background: 'rgba(0,0,0,0.5)',
          color: 'white',
          padding: '10px',
          backdropFilter: 'blur(10px)',
          borderRadius: '20px',
          width: '90%',
          maxWidth: '400px',
        }}>
          <Avatar src={profileUrl} alt="Profile" sx={{ width: 60, height: 60, borderRadius: '15px !important' }}/>
          <Typography variant="h5" component="p" sx={{ fontWeight: '800', fontFamily: 'Avenir', flexGrow: 1, textAlign: 'center', ml: 2 }}>
            {userData.first_name}
          </Typography>
          <Button
            variant="contained" 
            sx={{ 
              backgroundColor: 'orange', 
              color: 'black', 
              fontWeight: 'bold', 
              '&:hover': { 
                backgroundColor: 'darkorange', 
              }, 
            }} 
            onClick={() => setOpenSkins(true)}
          >
            Buy skin
          </Button>
        </Box>

        {/* Points and Rank Display */}
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '90%',
          maxWidth: '400px',
        }}>
          <Typography component="p" sx={{
            fontWeight: '800',
            fontFamily: 'avenir',
            color: 'aliceblue',
            fontSize: '25px',
            display: 'flex',
            alignItems: 'center',
          }}>
            <img style={{verticalAlign:'middle', marginRight: '10px'}} width="25" height="25" src="https://img.icons8.com/?size=100&id=CLkKtOPMmYwt&format=png&color=ffffff" alt="points" />
            {localPointCount}
          </Typography>
          <Typography component="p" sx={{
            fontWeight: '800',
            fontFamily: 'avenir',
            color: 'aliceblue',
            fontSize: '20px',
          }}>
            {getUserRank(localPointCount)}
          </Typography>
        </Box>

        {/* Coin Logo */}
        <CoinLogo
            component="img"
            src={userCurrentSkinImage || defaultCoin}
            alt="Coin Logo"
            onClick={handleCoinClick}
            sx={{
              animation: `${expand} 0.3s ease infinite`,
              cursor: 'pointer',
            }}
          />

        {/* Play Now Button */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Box sx={{
            backgroundColor: '#FFD700',
            borderRadius: '20px 0 0 20px',
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
          }}>
            <img src="https://img.icons8.com/?size=100&id=44853&format=png&color=000000" alt="Ticket" style={{ width: 24, height: 24, marginRight: 5 }} />
            <Typography sx={{ fontWeight: 'bold' }}>{tickets}</Typography>
          </Box>
          <PlayNowButton onClick={handlePlayNow}>
            Play now
          </PlayNowButton>
        </Box>

        {/* Navigation Buttons */}
        <Box sx={{ 
          width: '100%', 
          maxWidth: '400px',
          display: 'flex', 
          flexWrap: 'wrap',
          justifyContent: 'center', 
          alignItems: 'center', 
          mt: 2,
        }}>
          <GoldButton variant="contained" onClick={() => setOpenFriends(true)}>
            <img style={{ marginRight: '5px' }} width="20" height="20" src="https://img.icons8.com/?size=100&id=9542&format=png&color=ffffff" alt="invite" />
            Invite
          </GoldButton>
          <GoldButton variant="contained" onClick={() => setOpenLeaderboard(true)}>
            <img style={{ marginRight: '5px' }} width="20" height="20" src="https://img.icons8.com/?size=100&id=9762&format=png&color=ffffff" alt="stats" />
            Stats
          </GoldButton>
          <GoldButton variant="contained" onClick={() => setOpenWordGame(true)}>
            <img style={{ marginRight: '5px' }} width="20" height="20" src="https://img.icons8.com/?size=100&id=12725&format=png&color=ffffff" alt="word cipher" />
            Cipher
          </GoldButton>
          <GoldButton variant="contained" onClick={() => setOpenTasks(true)}>
            <img style={{ marginRight: '5px' }} width="20" height="20" src="https://img.icons8.com/?size=25&id=61532&format=png&color=ffffff" alt="tasks" />
            Earn
          </GoldButton>
        </Box>
  
          {/* Modals */}
          <SkinsModal open={openSkins} handleClose={() => setOpenSkins(false)} userData={userData} userSkins={userSkins} userCurrentSkin={userCurrentSkinID} />
          <FriendsModal open={openFriends} handleClose={() => setOpenFriends(false)} userData={userData} referralCount={userCurrentReferrals} referralList={userReferralsInfo === 'null' ? [] : userReferralsInfo} />
          <LeaderboardModal open={openLeaderboard} handleClose={() => setOpenLeaderboard(false)} userData={userData} leaderboardList={leaderboardList} />
          <TasksModal open={openTasks} handleClose={() => setOpenTasks(false)} userData={userData} setUserPoints={setPointCount}/>
          <WordScrambleGame open={openWordGame} handleClose={() => setOpenWordGame(false)} userData={userData} onGameEnd={handleWordGameEnd} setPointCount={setPointCount}/>
  
        </Box>
      </ThemeProvider>
    );
  }